(function () {
  'use strict';

  angular
    .module('events.vehicles.view')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.vehicles.view', {
        url: '/{vehicleId:.{36}}',
        templateUrl: 'events/vehicles/view/view.tpl.html',
        controller: 'VehiclesViewCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
